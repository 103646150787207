<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE DATA LIST BUYER ORDER</title>
    <section class="content-header">
      <h1>
        Update Data List Buyer Order
        <br />
        <h4>Please update the Buyer Order List transaction data</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Transactions List Buyer Order</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Buyer Order Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Order Number </label>
                  <input
                    type="text"
                    v-model="odernobuyer"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    readonly
                    v-model="idxbuyerorder"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Order Date </label>
                  <input
                    type="date"
                    v-model="tglorderbuyer"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty </label>
                  <input
                    type="text"
                    v-model="qtyorderbuyer"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Buyer</label>
                  <select2
                    :data="buyerlist"
                    :value="valuebuyer"
                    @update="updatebuyer($event)"
                  ></select2>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes </label>
                  <input
                    type="text"
                    v-model="notesorderbuyer"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Product</label>
                  <select2
                    :data="productlist"
                    :value="valueproduct"
                    @update="updateproduct($event)"
                  ></select2>
                </div>
              </div>
              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                >
                  <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/buyer-order">
                  <button class="btn btn-success">
                    <i class="fa fa-angle-double-left"></i> Back
                  </button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <i class="fa fa-edit"></i>
              <h3 class="box-title">Update Buyer Order Data</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Start Date Production </label>
                  <input
                    type="date"
                    v-model="mulai"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">End Date Production </label>
                  <input
                    type="date"
                    v-model="selesai"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Packaging</label>
                  <input
                    type="text"
                    v-model="pack"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">CMT Value</label>
                  <input
                    type="text"
                    v-model="cmtval"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">CMT</label>
                  <select name="cmt" class="form-control" v-model="cmt" id="cmtl">
                    <option value="APPWORK">App Work</option>
                    <option value="FOB">FOB</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Status</label>
                  <select name="status" class="form-control" v-model="status" id="statuss">
                    <option value="CMT">CMT</option>
                    <option value="FOB">FOB</option>
                  </select>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">FOB</label>
                  <input
                    type="text"
                    v-model="fob"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Shipment</label>
                  <input
                    type="text"
                    v-model="shipment"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Term</label>
                  <input
                    type="text"
                    v-model="termcond"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Consignee</label>
                  <input
                    type="text"
                    v-model="cons"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Currency</label>
                  <input
                    type="text"
                    v-model="curr"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addprosesdprod",
  data() {
    return {
      loading: false,
      pack: "",
      cmt: "",
      fob: "",
      shipment: "",
      termcond: "",
      cons: "",
      curr: "",
      odernobuyer: "",
      idxbuyerorder: "",
      tglorderbuyer: "",
      qtyorderbuyer: "",
      kodebuyer: "",
      namabuyer: "",
      notesorderbuyer: "",
      noprodh: "",
      namaproduk: "",
      mulai: "",
      selesai: "",
      cmtval: "",
      status: "",
      buyerlist: [],
      productlist: [],
      valuebuyer: "",
      valueproduct: ""
    };
  },
  created() {
    // this.fetchdata();
    this.getbuyerlist();
    this.getproductlist();
  },
  methods: {
    updatebuyer(value) {
      this.valuebuyer = value;
    },
    updateproduct(value) {
      this.valueproduct = value;
    },
    getbuyerlist() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/getproduk_hbynomor_produk?nomor_produk=" + this.isidata.noprodh;
      const urlAPIget = this.$apiurl + "buyer/getlistbuyer";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.buyerlist = resp.data.data;
          // console.log(this.buyerlist);
          this.buyerlist.forEach((item) => {
            item.value = item.kode_buyer;
            item.label = item.name;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getproductlist() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/produk_h/getproduk_hbynomor_produk?nomor_produk=" + this.isidata.noprodh;
      const urlAPIget = this.$apiurl + "produk_h/getdaftar_produk_list";
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.productlist = resp.data.data;
          this.productlist.forEach((item) => {
            item.value = item.nomor_produk;
            item.label = item.nama_produk;
          });
          this.loading = false;
          this.fetchdata()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchdata() {
      this.loading = true;
      // const urlAPIget = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order/getbuyer_orderbyid?id=" + this.$route.params.id;
      const urlAPIget =
        this.$apiurl +
        "buyer_order/getbuyer_orderbyid?id=" +
        this.$route.params.id;

      const tokenlogin = sessionStorage.getItem("token");
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.odernobuyer = resp.data.data.order_number;
          this.idxbuyerorder = resp.data.data.id;
          this.tglorderbuyer = resp.data.data.tanggal_order;
          this.valuebuyer = resp.data.data.kode_buyer;
          this.qtyorderbuyer = resp.data.data.qty;
          this.notesorderbuyer = resp.data.data.notes;
          this.valueproduct = resp.data.data.nomor_produk;
          this.mulai = resp.data.data.tanggal_produksi_start;
          this.selesai = resp.data.data.tanggal_produksi_end;
          this.pack = resp.data.data.packaging;
          this.cmt = resp.data.data.cmt;
          this.cmtval = resp.data.data.cmt_value;
          this.status = resp.data.data.status;
          this.fob = resp.data.data.fob_value;
          this.shipment = resp.data.data.shipment;
          this.termcond = resp.data.data.term;
          this.cons = resp.data.data.consignee;
          this.curr = resp.data.data.currency;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    async updateData() {
      this.loading = true;
      var ordnbyr = this.odernobuyer;
      var idxordby = this.idxbuyerorder;
      var tglo = this.tglorderbuyer;
      var qtyo = this.qtyorderbuyer;
      var kdbyr = this.valuebuyer;
      var ctnodrbyr = this.notesorderbuyer;
      var kdprdh = this.valueproduct;
      var st = this.mulai;
      var end = this.selesai;

      var pak = this.pack;
      var cm = this.cmt;
      var fo = this.fob;
      var ship = this.shipment;
      var tem = this.termcond;
      var con = this.cons;
      var cur = this.curr;

      var cval = this.cmtval;
      var stat = this.status;

      if (kdbyr == "" || idxordby == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Buyer can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (ordnbyr == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Order Number Buyer can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (tglo == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Order Date Buyer can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qtyo == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty Order Buyer can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (kdprdh == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Product can not be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdatadetail = {
          order_number: ordnbyr,
          tanggal_order: tglo,
          kode_buyer: kdbyr,
          qty: qtyo,
          notes: ctnodrbyr,
          nomor_produk: kdprdh,
          kode_user: kodeuser,
          tanggal_produksi_start: st,
          tanggal_produksi_end: end,
          packaging: pak,
          cmt: cm,
          fob_value: fo,
          shipment: ship,
          term: tem,
          consignee: con,
          currency: cur,
          status: stat,
          cmt_value: cval,
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        // const urlAPIUpdateDatadetail = "http://26.183.23.191/inventory/backend/inventory/api/buyer_order/updatebuyer_order/" + idxordby;
        const urlAPIUpdateDatadetail =
          this.$apiurl + "buyer_order/updatebuyer_order/" + idxordby;
        axios
          .put(urlAPIUpdateDatadetail, paramdatadetail, { headers: headers })
          .then((respn) => {
            // console.log(respn);
            // console.log(urlAPIUpdateDatadetail);
            // console.log(paramdatadetail);
            if (respn.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "buyerorder" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
